import React, { useState } from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    IconButton,
    TextField,
    InputAdornment,
} from "@mui/material";
import { t } from "i18next";
import SearchIcon from "@mui/icons-material/Search";
import { FindTransaction, FindTransactionWithPagination } from "../../../interfaces/FindCategoryTransactionInterface";
import TransactionCard from "../TransactionCard";
import CategoryListService from "../service/categoryService";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import Pagination from "../../common/Pagination";
import { useAppSelector } from "../../../redux/hooks";
import { LIST_LIMIT } from "../../../constants/constants";
import Skeleton from "../../common/Skeleton";

const FindPage = (props: any) => {
    const { showSnackbar, handleLoader } = props;
    const [skeleton, setSkeleton] = useState(false);
    const [searchedKeyword, setSearchedKeyword] = useState("");
    const [searchedTransactionData, setTransactionData] = useState<Array<FindTransaction>>([]);
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isSearch, setIsSearch] = useState<boolean>(false);
    


    const findTransactions = (currentPage: number, limit: number) => {
        setIsSearch(false);
        setSkeleton(true);
        new CategoryListService().findTransaction(currentPage, limit, searchedKeyword?.trim()).then((res: FindTransactionWithPagination) => {
            setTransactionData(res.searchedCategoryListLogs);
            setLimit(limit);
            setCurrentPage(currentPage);
            setTotalCount(res?.pagination?.totalCount);
            setTotalPages(res?.pagination?.totalPages);
            setSkeleton(false);
        }).catch((err : any)=>{
            showSnackbar(err?.message, false);
            setSkeleton(false);
        });
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setSearchedKeyword(value);
        setSkeleton(true);
        setIsSearch(true);
    };

    const handleSearchTransaction = () =>{
        if (isSearch) {
            findTransactions(1,limit);
        }
    };

    // for lazy typing functionality
    const searchPropItem = [
        handleSearchTransaction, searchedKeyword
    ];
    lazyTypingUtils(searchPropItem);

    return (
        <Container className="commonContainer">
            <Grid container className="search">
                <Grid item xs={12} sm={10} md={6} lg={5}>
                    <TextField
                        label={t("category_management.search_place_holder_product")}
                        onChange={handleSearchChange}
                        // onKeyUp={handleKeyPress}
                        value={searchedKeyword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disabled>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
            <Box >


                {skeleton ? (
                    <Skeleton count={4} height={90} />
                ) : searchedKeyword?.length > 0 && searchedTransactionData?.length === 0 ? (
                    <Typography align="center" mt={6}>
                        {t("category_management.no_data_found")}
                    </Typography>
                ) : 
                    searchedKeyword?.trim().length > 0 ? 
                        <TransactionCard isSuccess={false} transactionList={searchedTransactionData} isFind={true} showSnackbar={showSnackbar} apiCall={findTransactions} limit={limit}/>
                        :
                        <Box className="main_grid_searching_product">
                            <Grid container  className="searching_product_grid">
                                <Typography sx={{fontWeight: "400!important", fontSize:"large!important", color: "#b7b1b1!important"}}>{t("category_management.try_searching_product")}</Typography>
                            </Grid>
                        </Box>
                }

            </Box>
            {
                searchedKeyword && searchedTransactionData?.length > 0 &&
                            <Pagination
                                totalCount={totalCount}
                                currentPage={currentPage}
                                pageChange={findTransactions}
                                totalPage={totalPages}
                                rowsPerPage={limit}
                                rowsPerPageChange={setLimit}
                            />
            }

        </Container>
    );
};

export default FindPage;
export const mockAPIPath = {
    CATEGORY_LIST:"./data/categoryList.json",
    UPDATE_CATEGORY:"",
    FAILED_CATEGORY_REQUEST:"",
    SUCCESS_TRANSACTION_LIST : "",
    FIND_TRANSACTION : "",
    CATEGORY_INTEGRATION : ""

};

export const serverAPIPath = {
    CATEGORY_LIST:"/master/categories",
    UPDATE_CATEGORY:"/master/categories",
    CATEGORY_INTEGRATION : "/category-integration",
    FAILED_CATEGORY_REQUEST:"/category-integration/failed",
    SUCCESS_TRANSACTION_LIST : "/category-integration/success",
    FIND_TRANSACTION: "/category-integration/find"
};
import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    IconButton,
    TextField,
    InputAdornment,
} from "@mui/material";
import Skeleton from "../../common/Skeleton";
import { t } from "i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "../../../redux/hooks";
import { LIST_LIMIT } from "../../../constants/constants";
import Pagination from "../../common/Pagination";
import CategoryListService from "../service/categoryService";
import { FailedListLogType, FailedListWithPagination, FailedTransactionsType, ProductRecord } from "../../../interfaces/CategoryFailedTransactionInterface";
import TransactionCard from "../TransactionCard";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";

const FailedTransactions = (props:any) => {
    const { showSnackbar } = props;
    const [skeleton, setSkeleton] = useState(true);
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [failedCategoryData, setFailedCategoryData] = useState<FailedListLogType[]>([]);
    const [searchedKeyword, setSearchedKeyword] = useState<string>("");
    const [isSearch, setIsSearch] = useState<boolean>(false);

    /**
    * Fetches the initial failed category list when the component is mounted.
    */
    useEffect(() => {
        failedCategoryListApi(firstPage, limit);
    }, []);

    /**
    * Calls the API to fetch the failed category list with pagination.
    * @param currentPage - The current page number.
    * @param limit - Number of items to be fetched per page.
    */
    const failedCategoryListApi = (currentPage: number, limit: number) => {
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new CategoryListService(payload, isHitDummyURL)
            .failedCategoryList(currentPage, limit,searchedKeyword)
            .then((res: FailedListWithPagination) => {
                setFailedCategoryData(res?.failedCategoryListLogs);
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    /**
    * Handles the input change event for the search field.
    * Updates the searchKeyword state with the new value entered by the user.
    * 
    * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The input change event.
    */
    const onSearchInputChangehandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        setIsSearch(true);
        setSearchedKeyword(value);
    };

    /**
    * Triggers the search operation for successful transactions.
    * Calls the API only if the search keyword is not empty or whitespace.
    */
    const handleSearchTransaction = () =>{
        if(isSearch)
            failedCategoryListApi(1, limit);            
    };
    
    // for lazy typing functionality
    const searchPropItem = [
        handleSearchTransaction, searchedKeyword
    ];
    lazyTypingUtils(searchPropItem);

    return (
        <Container className="Container">
            <Grid container className="search">
                <Grid item xs={12} sm={10} md={6} lg={5}>
                    <TextField
                        label={t("category_management.search_place_holder_product")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disabled>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={onSearchInputChangehandler}
                        variant="outlined"
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={5} lg={7}></Grid>
            </Grid>
            {skeleton ? (
                <Skeleton count={4} height={90} />
            ) : failedCategoryData.length === 0 ? (
                <Typography align="center" mt={6}>
                    {t("category_management.no_data_found")}
                </Typography>
            ) : 
                <TransactionCard isSuccess={false} transactionList={failedCategoryData} isFind={false} showSnackbar={showSnackbar} apiCall={failedCategoryListApi} limit={limit}/>
            }
            {
                failedCategoryData.length > 0 &&
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={failedCategoryListApi}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            }
        </Container>
    );
};

export default FailedTransactions;
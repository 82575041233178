import React, { useEffect, useState } from "react";
import { CategorySuccessTransaction } from "../../interfaces/CategorySuccessTransactionInterface";
import { FailedListLogType, FailedTransactionsType, ProductCategoryMappingRequest, ProductRecord } from "../../interfaces/CategoryFailedTransactionInterface";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FindTransaction } from "../../interfaces/FindCategoryTransactionInterface";
import CategoryListService from "./service/categoryService";
import CommonButton from "../common/Button";

interface TransactionCardProps<T extends boolean> {
    isSuccess: T;
    transactionList: T extends true ? CategorySuccessTransaction[] : FailedListLogType[] | FindTransaction[];
    isFind: boolean;
    showSnackbar ?:any
    apiCall? : any;
    limit ?: number;
}

const isCategorySuccessTransaction = (
    item: CategorySuccessTransaction | FailedTransactionsType | FindTransaction
): item is CategorySuccessTransaction => {
    return (item as CategorySuccessTransaction) !== undefined;
};


const TransactionCard = <T extends boolean>(props: TransactionCardProps<T>) => {

    const { isSuccess, transactionList, isFind, showSnackbar,apiCall,limit } = props;

    const [expanded, setExpanded] = useState<any>(false);
    const [activeAccordionIndex, setActiveAccordionIndex] = useState<number | null>(null);
    const [accordionPayloadData, setAccordionPayloadData] = useState<{ [key: number]: ProductCategoryMappingRequest[] }>({});
    const [accordionButtonStates, setAccordionButtonStates] = useState<{ [key: number]: boolean }>({});

    const handleAccordion = (panel: string, index: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        setActiveAccordionIndex(isExpanded ? index : null);
    };    

    const shouldDisableUpdateButton = (payloadData: ProductCategoryMappingRequest[]): boolean => {        
        if (!payloadData || payloadData.length === 0) return true;
    
        // Function to check if a row is completely filled (both fields have valid values)
        const isRowFilled = (row: ProductCategoryMappingRequest) => {
            return row?.categoryCode !== undefined && row?.categoryCode?.trim()!=="" && row?.publishStatus !==undefined;
        };
    
        // Function to check if a row is partially filled (one field is missing)
        const isRowHalfFilled = (row: ProductCategoryMappingRequest) => {
            return (row?.publishStatus === undefined && (row?.categoryCode && row?.categoryCode?.trim()!=="" )) || 
                ( (row?.categoryCode === undefined || row?.categoryCode?.trim()==="") && row?.publishStatus !== undefined);
        };
    
        // If there are two rows, check conditions:
        const isFirstRowFilled = isRowFilled(payloadData[0]);
        const isSecondRowFilled = isRowFilled(payloadData[1]);
        const isFirstRowHalfFilled = isRowHalfFilled(payloadData[0]);
        const isSecondRowHalfFilled = isRowHalfFilled(payloadData[1]);

        if((isFirstRowFilled || isSecondRowFilled ) && !(isFirstRowHalfFilled || isSecondRowHalfFilled)){
            return false;
        }

        if((isFirstRowHalfFilled || isSecondRowHalfFilled)){
            return true;
        }

        return true; // Disable button
    };
    
    useEffect(() => {
        if (activeAccordionIndex !== null) {
            const currentPayload = accordionPayloadData[activeAccordionIndex] || [];
            setAccordionButtonStates(prev => ({
                ...prev,
                [activeAccordionIndex]: shouldDisableUpdateButton(currentPayload)
            }));
        }
    }, [activeAccordionIndex, accordionPayloadData]);

    // Effect hook to update payloadData when we get the prefilled fields
    useEffect(() => {
        if (!isSuccess && Array.isArray(transactionList)) {
            transactionList.forEach((item, index) => {
                if ("failedCategory" in item && item.failedCategory.length > 0) {
                    const { fromPreviousValue, toPreviousValue } = extractPreviousFailedValues(item.failedCategory);
                    const shouldHaveTwoObjects = inputFieldDecider(item.failedCategory);
    
                    setAccordionPayloadData(prev => ({
                        ...prev,
                        [index]: [
                            {
                                categoryCode: fromPreviousValue?.categoryCode || "",
                                publishStatus: (fromPreviousValue?.publishStatus ?? null) as number,
                            },
                            shouldHaveTwoObjects ? {
                                categoryCode: toPreviousValue?.categoryCode || "",
                                publishStatus: toPreviousValue?.publishStatus ?? null,
                            } : null
                        ].filter(Boolean) as ProductCategoryMappingRequest[]
                    }));
                }else{
                    setAccordionPayloadData(prev => ({
                        ...prev,
                        [index]: [
                            {
                                categoryCode: item.productCategory,
                                publishStatus: 9,
                            },
                        ].filter(Boolean) as ProductCategoryMappingRequest[]
                    }));
                }
            });
        }
    }, [transactionList, isSuccess]);

    /**
    * Returns the flag status based on the provided publicStatus value.
    * 
    * @param {number} publicStatus - The public status code.
    * @returns {string} - The corresponding status as a string.
    */
    const getFlagStatus = (publicStatus: number) => {
        switch (publicStatus) {
        case 0:
            return t("category_management.private_flag_status");
        case 1:
            return t("category_management.public_flag_status");
        case 9:
            return t("category_management.archive_flag_status");
        }
    };

    /**
        * Determines if any old categories differ from new categories in the failed transaction list.
        * @param failedTransactionList - List of failed transactions.
        * @returns True if any oldCategory differs from newCategory, otherwise false.
        */
    const inputFieldDecider = (failedTransactionList: FailedTransactionsType[]): boolean => {
        for (const item of failedTransactionList) {
            if (item.oldCategory !== item.newCategory) {
                return true;
            }
        }
        return false;
    };

    const showSuccessOrFindData = (item : CategorySuccessTransaction, fieldName : "from"|"to" | "fromFlag"|"toFlag" | "time"| "responseCodeFrom" | "responseCodeTo") => {
        switch(fieldName){
        case "from":
            if(isSuccess)
                return item?.categoryFrom?.categoryCode??(item?.oldCategoryStatus !== null? item.productCategory : "-");
            else
                return item?.successCategory?.categoryFrom?.categoryCode;
        case "fromFlag":
            if(isSuccess)
                return item?.oldCategoryStatus;
            else 
                return item?.successCategory?.categoryFrom?.publicFlag;
        case "toFlag":
            if(isSuccess){
                return item?.categoryTo?.publicFlag;
            }
            else 
                return item?.successCategory?.categoryTo?.publicFlag;
        case "to":
            if(isSuccess)
                return item?.categoryTo?.categoryCode??"-";
            else
                return item?.successCategory?.categoryTo?.categoryCode;

        case "time":
            if(isSuccess)
                return item?.categoryTo?.updatedAt;
            else
                return item?.successCategory?.categoryTo?.updatedAt;
        case "responseCodeFrom":
            if(isSuccess)
                return item?.categoryFrom?.responseCode === 200 ? "success" : null;
            else
                return item?.successCategory?.categoryFrom?.requestStatus? (item?.successCategory?.categoryFrom?.requestStatus === 2) ? "success" : null: (item?.successCategory?.categoryFrom?.categoryCode)?"success" : null;
        case "responseCodeTo":
            if(isSuccess)
                return item?.categoryTo?.responseCode === 200 ? "success" : null;
            else
                return item?.successCategory?.categoryTo?.requestStatus? (item?.successCategory?.categoryTo?.requestStatus === 2) ? "success" : null:(item?.successCategory?.categoryTo?.categoryCode)?"success":null;
        }
    };

    /**
        * @description to show the status value text according to status code.
        * @param requestStatusCode - request status code.
        * @returns string - text for each status code.
        */

    const getRequestStatus = (requestStatusCode : number) => {
        switch(requestStatusCode){
        case 0:
            return t("category_management.idle_request_status");
        case 1: 
            return t("category_management.pending_request_status");
        case 2:
            return t("category_management.success_request_status");
        case 3:
            return t("category_management.failed_request_status");
        }
    }; 


    /**
    * Reorders payloadData if necessary, ensuring archived status is last.
    * @param payload - The payload data array.
    * @returns Reordered payload data.
    */
    const reOrderPayloadData = (payload: ProductCategoryMappingRequest[]) => {
        const reOrderedData = [...payload];
        if(reOrderedData[1]?.publishStatus === 9){
            [reOrderedData[1],reOrderedData[0]] = [reOrderedData[0],reOrderedData[1]];
        }        
        return reOrderedData;
    };

    /**
     * 
     * @param cainzProductCode - The Cainz product code.
     * @returns void
     * @description Calls the productCategoryMapping Api to update the product category mapping.
     */

    const productCategoryMapping = (cainzProductCode: string, index: number) => {
        const currentPayload = accordionPayloadData[index] || [];
        if (reOrderPayloadData(currentPayload)) {
            new CategoryListService(reOrderPayloadData(currentPayload)).productCategoryMapping(cainzProductCode)
                .then((res) => {
                    showSnackbar(res.message, true);
                    apiCall(1,limit);
                })
                .catch((err) => {
                    showSnackbar(err?.message, false);
                });
        }
    };

    /**
    * Extracts previous category values from a failed transaction list.
    * @param failedCategory - List of failed category transactions.
    * @returns Extracted previous values for comparison.
    */
    const extractPreviousFailedValues = (failedCategory :FailedTransactionsType[]) => {
        if (!failedCategory || failedCategory.length === 0) {
            return { fromPreviousValue: null, toPreviousValue: null };
        }
    
        const firstTransaction = failedCategory[0];
        const lastTransaction = failedCategory[failedCategory.length - 1];
    
        return {
            fromPreviousValue: {
                categoryCode: firstTransaction.records[0]?.categoryCode || "",
                publishStatus: firstTransaction.records[0]?.publicFlag || ""
            },
            toPreviousValue: {
                categoryCode: lastTransaction.records[lastTransaction.records.length - 1]?.categoryCode,
                publishStatus: lastTransaction.records[lastTransaction.records.length - 1]?.publicFlag 
            }
        };
    };

    /**
     * 
     * @param event - The event object from the input change.
     * @param item - The item object of type FailedTransactionsType
     * @param index - The index of the item in the failedCategory array.
     * @param fieldType - The type of field being changed ("from" or "to").
     * @returns void
     * @description Handles the change event for category code in the failed transaction list.
     */

    const failedCategoryChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        item: any,
        index: number,
        fieldType: "from" | "to"
    ) => {
        const newCategoryCode = event.target.value.trim();
        const accordionIndex = activeAccordionIndex;
        if (accordionIndex === null) return;
    
        setAccordionPayloadData(prev => {
            const updatedData = [...(prev[accordionIndex] || [])];
            const fieldIndex = fieldType === "from" ? 0 : 1;
    
            if (newCategoryCode) {
                updatedData[fieldIndex] = {
                    ...updatedData[fieldIndex],
                    categoryCode: newCategoryCode,
                };
            } else if (updatedData[fieldIndex]) {
                if (!updatedData[fieldIndex].publishStatus) {
                    updatedData.splice(fieldIndex, 1);
                } else {
                    updatedData[fieldIndex] = { ...updatedData[fieldIndex] };
                    delete updatedData[fieldIndex].categoryCode;
                }
            }
    
            return {
                ...prev,
                [accordionIndex]: updatedData.filter(obj => obj.categoryCode || obj.publishStatus)
            };
        });
    };
    
    /**
     * 
     * @param event - The event object from the input change.
     * @param index - The index of the item in the failedCategory array.
     * @param fieldType - The type of field being changed ("from" or "to").
     * @returns void
     * @description Handles the change event for publishStatus in the failed transaction list.
     */
    const failedPublishStatusChangeHandler = (
        event: SelectChangeEvent<unknown>,
        index: number,
        fieldType: "from" | "to"
    ) => {
        const newStatus = event.target.value as number;
        const accordionIndex = activeAccordionIndex;
        if (accordionIndex === null) return;
    
        setAccordionPayloadData(prev => {
            const updatedData = [...(prev[accordionIndex] || [])];
            const fieldIndex = fieldType === "from" ? 0 : 1;
    
            if (newStatus !== undefined) {
                updatedData[fieldIndex] = {
                    ...updatedData[fieldIndex],
                    publishStatus: newStatus,
                };
            } else if (updatedData[fieldIndex]) {
                if (!updatedData[fieldIndex].categoryCode) {
                    updatedData.splice(fieldIndex, 1);
                } else {
                    updatedData[fieldIndex] = { ...updatedData[fieldIndex] };
                    delete updatedData[fieldIndex].publishStatus;
                }
            }
    
            return {
                ...prev,
                [accordionIndex]: updatedData.filter(obj => obj.categoryCode || obj.publishStatus)
            };
        });
    };
    
    /**
     * 
     * @param event - The event object from the input change.
     * @param fieldType - The type of field being changed (from or to).
     * @returns void
     * @description Handles the change event for category code in the success transaction list.
     */
    const successCategoryChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldType: "from" | "to"
    ) => {
        const newCategoryCode = event.target.value;
        const accordionIndex = activeAccordionIndex;
        if (accordionIndex === null) return;
        
        setAccordionPayloadData(prev => {
            const updatedData = [...(prev[accordionIndex] || [])];
            const fieldIndex = fieldType === "from" ? 0 : 1;
            
            updatedData[fieldIndex] = { 
                ...updatedData[fieldIndex], 
                categoryCode: newCategoryCode 
            };
            
            return {
                ...prev,
                [accordionIndex]: updatedData
            };
        });
    };


    /**
     * 
     * @param event - The event object from the input change.
     * @param fieldType - The type of field being changed (from or to).
     * @returns void
     * @description Handles the change event for publishStatus in the success transaction list.
     */
    const successPublishStatusChangeHandler = (
        event: SelectChangeEvent<unknown>,
        fieldType: "from" | "to"
    ) => {
        const newStatus = event.target.value as number;
        const accordionIndex = activeAccordionIndex;
        if (accordionIndex === null) return;
    
        setAccordionPayloadData(prev => {
            const updatedData = [...(prev[accordionIndex] || [])];
            const fieldIndex = fieldType === "from" ? 0 : 1;
            
            updatedData[fieldIndex] = { 
                ...updatedData[fieldIndex], 
                publishStatus: newStatus 
            };
            
            return {
                ...prev,
                [accordionIndex]: updatedData
            };
        });
    };
    
    return (
        <Grid mt={3}>
            {
                transactionList?.map((item : any, index) =>
                    (
                        <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleAccordion(`panel${index}`, index)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                sx={{userSelect:"text"}}
                            >
                                <Grid container className="text_wrap">
                                    <Grid item xs={12} sm={3} md={3} lg={1} mb={1}>
                                        <Grid>
                                            <Typography className="main_content_of_accordion" variant="body1">{t("category_management.store_code")}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography className="main_accordion_data" variant="body1">{item?.storeCode}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={1} mb={1}>
                                        <Grid>
                                            <Typography className="main_content_of_accordion" variant="body1">{t("category_management.seller_name")}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography className="main_accordion_data" variant="body1">{item?.storeName}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={1} mb={1}>
                                        <Grid>
                                            <Typography className="main_content_of_accordion" variant="body1">{t("category_management.product_code")}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography className="main_accordion_data" variant="body1">{item?.productCode}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={1} mb={1}>
                                        <Grid>
                                            <Typography className="main_content_of_accordion" variant="body1">{t("category_management.cp_code")}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography className="main_accordion_data" variant="body1">{item?.cainzProductCode}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={5} mb={1}>
                                        <Grid>
                                            <Typography className="main_content_of_accordion" variant="body1">{t("category_management.product_name")}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography className="main_accordion_data" variant="body1">{item?.productName}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={1} mb={1}>
                                        <Grid>
                                            <Typography className="main_content_of_accordion" variant="body1">{t("category_management.public_flag")}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography className="main_accordion_data" variant="body1">{getFlagStatus(Number(item?.publicFlag))}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={1} mb={1}>
                                        <Grid>
                                            <Typography className="main_content_of_accordion" variant="body1">{t("category_management.category_code")}</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography className="main_accordion_data" variant="body1">{item?.productCategory}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                { 
                                    (!isSuccess || isFind) && item?.failedCategory?.map((failedTransaction: FailedTransactionsType, idx: number) =>
                                        (
                                            <Accordion defaultExpanded sx={{ boxShadow: "none" }} className="insided_accordion" key={idx}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel${index}-content`}
                                                    id={`panel${index}-header`}
                                                >
                                                    <Grid container>
                                                        <Grid container item xs={12} sm={4} md={4} lg={4}>
                                                            <Grid>
                                                                <Typography variant="body1">{"["+t("category_management.from")+"]"}{t("category_management.category_code")}</Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body1">: {failedTransaction?.oldCategory}/</Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body1">{t("category_management.posting_status")}</Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body1">: {getFlagStatus(failedTransaction?.oldCategoryStatus)}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} sm={4} md={4} lg={4}>  
                                                            <Grid>
                                                                <Typography variant="body1">{"["+t("category_management.to")+"]"}{t("category_management.category_code")}</Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body1">:  {failedTransaction?.newCategory}/</Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body1">{t("category_management.posting_status")}</Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body1">: {getFlagStatus(failedTransaction?.records[1] ? (failedTransaction?.records[1]?.publicFlag):(failedTransaction?.records[0]?.publicFlag))}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item xs={12} sm={4} md={4} lg={3}>
                                                            <Grid>
                                                                <Typography variant="body1">{t("category_management.time")} :</Typography>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body1"> &nbsp;{failedTransaction?.createdAt}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                {failedTransaction?.records?.map((record: ProductRecord, recordIdx: number) => {
                                                                    return (
                                                                        <Grid key={recordIdx} container mb={2}>
                                                                            <Grid container xs={12} sm={4} md={3} lg={2}>
                                                                                <Grid>
                                                                                    <Typography variant="body1">{t("category_management.category")}</Typography>
                                                                                </Grid>
                                                                                <Grid>
                                                                                    <Typography variant="body1">:  {record?.categoryCode}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container xs={12} sm={4} md={3} lg={2}>
                                                                                <Grid>
                                                                                    <Typography variant="body1">{t("category_management.public_flag")}:</Typography>
                                                                                </Grid>
                                                                                <Grid>
                                                                                    <Typography variant="body1">&nbsp;{getFlagStatus( record?.publicFlag)}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container xs={12} sm={4} md={3} lg={2}>
                                                                                <Grid>
                                                                                    <Typography variant="body1">{t("category_management.request_status")}</Typography>
                                                                                </Grid>
                                                                                <Grid>
                                                                                    <Typography variant="body1">:<span style={{ color: record?.requestStatus === 3 ? "red":"" }}> {getRequestStatus(record?.requestStatus)} </span></Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            item?.failedCategory?.length === idx + 1 &&
                                                    <Grid>
                                                        {
                                                            inputFieldDecider(item.failedCategory) ?
                                                                <>
                                                                    <Grid container spacing={2} mb={1}>
                                                                        <Grid item xs={8} sm={6} md={3} lg={2}>
                                                                            <TextField
                                                                                label={t("category_management.from")}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                size="small"
                                                                                defaultValue={extractPreviousFailedValues(item.failedCategory)?.fromPreviousValue?.categoryCode}
                                                                                onChange={(event) => failedCategoryChangeHandler(event,item, idx, "from")}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4} sm={3} md={2} lg={1}>
                                                                            <FormControl fullWidth size="small">
                                                                                <InputLabel id="demo-simple-select-label" className="insided_accordion">{t("category_management.status")}</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    fullWidth
                                                                                    defaultValue={extractPreviousFailedValues(item.failedCategory)?.fromPreviousValue?.publishStatus}
                                                                                    onChange={(event) => failedPublishStatusChangeHandler(event, idx, "from")}                                                                                >
                                                                                    <MenuItem value={0}>0</MenuItem>
                                                                                    <MenuItem value={1}>1</MenuItem>
                                                                                    <MenuItem value={9}>9</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={2} mb={1} mt={2}>
                                                                        <Grid item xs={8} sm={6} md={3} lg={2}>
                                                                            <TextField
                                                                                label={t("category_management.to")}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                size="small" 
                                                                                defaultValue={extractPreviousFailedValues(item.failedCategory)?.toPreviousValue?.categoryCode}
                                                                                onChange={(event) => failedCategoryChangeHandler(event,item, idx, "to")}/>
                                                                        </Grid>
                                                                        <Grid item xs={4} sm={3} md={2} lg={1}>
                                                                            <FormControl fullWidth size="small">
                                                                                <InputLabel id="demo-simple-select-label" className="insided_accordion">{t("category_management.status")}</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    fullWidth
                                                                                    defaultValue={extractPreviousFailedValues(item.failedCategory)?.toPreviousValue?.publishStatus}
                                                                                    onChange={(event) => failedPublishStatusChangeHandler(event, idx, "to")}  
                                                                                >
                                                                                    <MenuItem value={0}>0</MenuItem>
                                                                                    <MenuItem value={1}>1</MenuItem>
                                                                                    <MenuItem value={9}>9</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                                :
                                                                <Grid container xs={12} sm={12} md={12} lg={12} spacing={2} mb={1}>
                                                                    <Grid item xs={8} sm={6} md={3} lg={2}>
                                                                        <TextField
                                                                            label={t("category_management.to")}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            size="small" 
                                                                            defaultValue={extractPreviousFailedValues(item.failedCategory)?.toPreviousValue?.categoryCode}
                                                                            onChange={(event) => failedCategoryChangeHandler(event,item, idx, "from")}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4} sm={3} md={2} lg={1} >
                                                                        <FormControl fullWidth size="small">
                                                                            <InputLabel id="demo-simple-select-label" className="insided_accordion">{t("category_management.status")}</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                fullWidth
                                                                                defaultValue={extractPreviousFailedValues(item.failedCategory)?.toPreviousValue?.publishStatus}
                                                                                onChange={(event) => failedPublishStatusChangeHandler(event, idx, "from")}  
                                                                            >
                                                                                <MenuItem value={0}>0</MenuItem>
                                                                                <MenuItem value={1}>1</MenuItem>
                                                                                <MenuItem value={9}>9</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                        }
                                                        <Grid>
                                                            <CommonButton 
                                                                sx={{ height: 40 }} 
                                                                functionToExecute={() => productCategoryMapping(item?.cainzProductCode, index)} 
                                                                className="" 
                                                                btnName={t("category_management.update")} 
                                                                disableCondition={
                                                                    activeAccordionIndex !== index || 
                                                                    accordionButtonStates[index] || 
                                                                    !accordionPayloadData[index]?.length
                                                                } 
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    )
                                }
                                {
                                    (isSuccess || isFind) && (isSuccess|| item?.failedCategory?.length ===0) && isCategorySuccessTransaction(item) && 
                                    <Grid  className={showSuccessOrFindData(item,"from") || !isSuccess?"insided_accordion": "" } p={item?.failedCategory?.length === 0 || item.categoryTo ? 2:0} >
                                        
                                        <Accordion defaultExpanded sx={{ boxShadow: "none" }} className="insided_accordion">
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel${index}-content`}
                                                id={`panel${index}-header`}
                                            >
                                                <Grid container>
                                                    {  (isSuccess || (isFind && item.failedCategory.length === 0)) &&
                                                       
                                                       <Grid container item xs={12} sm={4} md={4} lg={4}>
                                                           <Grid>
                                                               <Typography variant="body1">{"["+t("category_management.from")+"]"}{t("category_management.category_code")}</Typography>
                                                           </Grid>
                                                           <Grid>
                                                               <Typography variant="body1">: {showSuccessOrFindData(item,"from")}/</Typography>
                                                           </Grid>
                                                           <Grid>
                                                               <Typography variant="body1">{t("category_management.posting_status")}</Typography>
                                                           </Grid>
                                                           <Grid>
                                                               <Typography variant="body1">:{getFlagStatus(showSuccessOrFindData(item,"fromFlag")as number)}</Typography>
                                                           </Grid>
                                                       </Grid>
                                                    }

                                                    { (isSuccess || (isFind && item.failedCategory.length === 0)) &&
                                                         <Grid container item xs={12} sm={4} md={4} lg={4}>  
                                                             <Grid>
                                                                 <Typography variant="body1">  {"["+t("category_management.to")+"]"}{t("category_management.category_code")} </Typography>
                                                             </Grid>
                                                             <Grid>
                                                                 <Typography variant="body1">: {showSuccessOrFindData(item,"to")}/</Typography>
                                                             </Grid>
                                                             <Grid>
                                                                 <Typography variant="body1">{t("category_management.posting_status")}</Typography>
                                                             </Grid>
                                                             <Grid>
                                                                 <Typography variant="body1">:{getFlagStatus(showSuccessOrFindData(item,"toFlag")as number)}</Typography>
                                                             </Grid>
                                                         </Grid> 

                                                    }
                                       
                                                    { (isSuccess || (isFind && item.failedCategory.length === 0)) &&
                                                          <Grid item xs={12} sm={4} md={4} lg={4}>
                                                              <Grid>
                                                                  <Typography variant="body1">{t("category_management.time")}:&nbsp;{showSuccessOrFindData(item,"time")}</Typography>
                                                              </Grid>
                                                          </Grid>          
                                                    }
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {
                                                    (isSuccess || (isFind && item?.failedCategory?.length === 0)) &&
                                                        <Grid >
                                                            <Grid container mb={2}>
                                                                <Grid container xs={12} sm={4} md={4} lg={4}>
                                                                    <Grid>
                                                                        <Typography variant="body1">{t("category_management.category")}</Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="body1">: {showSuccessOrFindData(item,"from")}</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container xs={12} sm={4} md={4} lg={4}>
                                                                    <Grid>
                                                                        <Typography variant="body1">{t("category_management.public_flag")}:</Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="body1">&nbsp;{getFlagStatus(item?.categoryFrom?.publicFlag || item?.successCategory?.categoryFrom?.publicFlag)}</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container xs={12} sm={4} md={4} lg={4}>
                                                                    <Grid>
                                                                        <Typography variant="body1">{t("category_management.request_status")}</Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="body1">:<span > {showSuccessOrFindData(item,"responseCodeFrom")} </span></Typography>
                                                                    </Grid>
                                                                </Grid> 
                                                            </Grid>

                                                            <Grid container  >
                                                                <Grid container xs={12} sm={4} md={4} lg={4}>
                                                                    <Grid>
                                                                        <Typography variant="body1">{t("category_management.category")}</Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="body1">: {showSuccessOrFindData(item,"to")}</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container xs={12} sm={4} md={4} lg={4}>
                                                                    <Grid>
                                                                        <Typography variant="body1">{t("category_management.public_flag")}:</Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="body1">&nbsp;{getFlagStatus(item?.categoryTo?.publicFlag || item?.successCategory?.categoryTo?.publicFlag)}</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container xs={12} sm={4} md={4} lg={4}>
                                                                    <Grid>
                                                                        <Typography variant="body1">{t("category_management.request_status")}</Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="body1">:<span > {showSuccessOrFindData(item,"responseCodeTo")} </span></Typography>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>  
                                                        </Grid>                                               
                                                }
                                                {isFind && item.failedCategory.length === 0 &&
                                        (<>
                                            <Grid container spacing={1} mt={1}>
                                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                                    <TextField
                                                        label={t("category_management.category")}
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small" 
                                                        defaultValue={item.productCategory}
                                                        onChange={(event) => successCategoryChangeHandler(event, "from")}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2} md={2} lg={1}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="demo-simple-select-label" className="insided_accordion">{t("category_management.status")}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            onChange={(event) => successPublishStatusChangeHandler(event, "from")}
                                                            defaultValue={9}
                                                        >
                                                            <MenuItem value={0}>0</MenuItem>
                                                            <MenuItem value={1}>1</MenuItem>
                                                            <MenuItem value={9}>9</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                                    <TextField
                                                        label={t("category_management.category")}
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(event) => successCategoryChangeHandler(event, "to")}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2} md={2} lg={1}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="demo-simple-select-label" className="insided_accordion">{t("category_management.status")}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            onChange={(event) => successPublishStatusChangeHandler(event, "to")}
                                                        >
                                                            <MenuItem value={0}>0</MenuItem>
                                                            <MenuItem value={1}>1</MenuItem>
                                                            <MenuItem value={9}>9</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid mt={1}>
                                                <CommonButton 
                                                    sx={{ height: 40 }} 
                                                    functionToExecute={() => productCategoryMapping(item?.cainzProductCode, index)} 
                                                    className="" 
                                                    btnName={t("category_management.update")} 
                                                    disableCondition={
                                                        activeAccordionIndex !== index || 
                                                        accordionButtonStates[index] || 
                                                        !accordionPayloadData[index]?.length
                                                    } 
                                                />
                                            </Grid>
                                        </>
                                        )
                                                }

                                            </AccordionDetails>
                                        </Accordion>
                                       
                                        
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                    )
                )
            }
        </Grid>
    );
};

export default TransactionCard;

import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import Skeleton from "../../common/Skeleton";
import { t } from "i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "../../../redux/hooks";
import { LIST_LIMIT } from "../../../constants/constants";
import Pagination from "../../common/Pagination";
import CategoryListService from "../service/categoryService";
import { CategorySuccessTransaction, CategorySuccessTransactionWithPagination as SuccessTransactionWithPagination } from "../../../interfaces/CategorySuccessTransactionInterface";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import TransactionCard from "../TransactionCard";

const SuccessTransactions = (props: any) => {
    const { showSnackbar} = props;
    const [skeleton, setSkeleton] = useState(true);
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit);
    const [successCategoryData, setSuccessCategoryData] = useState<CategorySuccessTransaction[]>([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isSearch, setIsSearch] = useState<boolean>(false);

    useEffect(() => {
        // Fetch the list of successful transactions whenever the current page or search keyword changes
        getSuccessTransactionsList(currentPage, limit);
    }, []);

    /**
    * Fetches the list of successful transactions based on the current page, limit, and search keyword.
    * It sets the skeleton loading state, makes an API call, updates the relevant state variables, 
    * and handles errors by displaying a snackbar notification.
    * 
    * @param {number} currentPage - The current page number.
    * @param {number} limit - The number of records per page.
    */
    const getSuccessTransactionsList = (currentPage: number, limit: number) => {
        setIsSearch(false);
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new CategoryListService(payload, isHitDummyURL)
            .successCategoryList(currentPage, limit,searchKeyword)
            .then((res: SuccessTransactionWithPagination) => {                
                setSuccessCategoryData(res?.successCategoryListLogs);
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };

    /**
    * Handles the input change event for the search field.
    * Updates the searchKeyword state with the new value entered by the user.
    * 
    * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The input change event.
    */
    const onSearchInputChangehandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        setIsSearch(true);
        setSearchKeyword(value);
    };

    /**
    * Triggers the search operation for successful transactions.
    * Calls the API only if the search keyword is not empty or whitespace.
    */
    const handleSearchTransaction = () =>{
        if(isSearch)
            getSuccessTransactionsList(1, limit);            
    };

    // for lazy typing functionality
    const searchPropItem = [
        handleSearchTransaction, searchKeyword
    ];
    lazyTypingUtils(searchPropItem);



    return (
        <Container className="commonContainer">
            <Grid container className="search">
                <Grid item xs={12} sm={10} md={6} lg={5}>
                    <TextField
                        label={t("category_management.search_place_holder_product")}
                        onChange={onSearchInputChangehandler}
                        value={searchKeyword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disabled>
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={5} lg={7}></Grid>
            </Grid>
            {
                skeleton ? (
                    <Skeleton count={5} height={90} />
                ) : 
                    <Grid className="accordion">
                        {
                            successCategoryData?.length > 0 ?
                                <TransactionCard isSuccess={true} transactionList={successCategoryData} isFind={false} showSnackbar={showSnackbar}/>
                                :
                                <Grid textAlign={"center"} mt={6}>{t("category_management.no_data_found")}</Grid>
                        }
                    </Grid>
            }
            {
                successCategoryData?.length > 0 &&
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={getSuccessTransactionsList}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            }
        </Container>
    );
};
export default SuccessTransactions;
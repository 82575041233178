import React,{useState}  from "react";
import {
    Container,
    Grid,
    Box,
    Tab
} from "@mui/material";
import PageTitle from "../../common/PageTitle";
import Skeleton from "../../common/Skeleton";
import { t } from "i18next";
import Button from "@mui/material/Button";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FailedTransactions from "../FailedTransactons";
import SuccessTransactions from "../SuccessTransactions";
import FindPage from "../FindPage";

const CategorySettings = (props:any) => {
    const {showSnackbar,handleLoader}=props;
    const [skeleton, setSkeleton] = useState(true);
    const [value, setValue] = useState<string>("1");

    
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <Container>
                <Grid >
                    <PageTitle title={t("category_management.category_reflection")} />
                </Grid>
                <Grid className="tabcontent">
                    <TabContext value={value}>
                        <Box >
                            <TabList
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                className="tablist"
                                sx={{
                                    textTransform: "capitalize",
                                    mr: 2,
                                }}
                            >
                                <Tab
                                    iconPosition="start"
                                    label={t("category_management.failed_transactions")}
                                    value="1"
                                    sx={{
                                        borderBottom: 2,
                                        borderColor: "divider",
                                        mr: 2, 
                                    }}
                                    
                                />
                                <Tab
                                    iconPosition="start"
                                    label={t("category_management.success_transactions")}
                                    sx={{
                                        borderBottom: 2,
                                        borderColor: "divider",
                                        mr: 2,
                                    }}
                                    value="2"
                                />
                                <Tab
                                    iconPosition="start"
                                    label={t("category_management.find")}
                                    sx={{
                                        borderBottom: 2,
                                        borderColor: "divider",
                                        mr: 2,
                                    }}
                                    value="3"
                                />
                            </TabList>
                        </Box>

                        <TabPanel value="1" sx={{ p: 0 }}>
                            <FailedTransactions
                                handleLoader={handleLoader}
                                showSnackbar={showSnackbar}
                            />
                        </TabPanel> 
                        <TabPanel value="2" sx={{ p: 0 }}>
                            <SuccessTransactions
                                handleLoader={handleLoader}
                                showSnackbar={showSnackbar}
                            />
                        </TabPanel>
                        <TabPanel value="3" sx={{ p: 0 }}>
                            <FindPage
                                handleLoader={handleLoader}
                                showSnackbar={showSnackbar}
                            />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Container>
        </>
    );
};

export default CategorySettings;



   
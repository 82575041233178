import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { FailedListWithPagination } from "../../../interfaces/CategoryFailedTransactionInterface";
import { CategoryListWithPaginationType } from "../../../interfaces/categoryListInterface";
import { CategorySuccessTransactionWithPagination } from "../../../interfaces/CategorySuccessTransactionInterface";
import { FindTransactionWithPagination } from "../../../interfaces/FindCategoryTransactionInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class CategoryListService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }
    async categoryList(
        page_id: number,
        limit = LIST_LIMIT.CATEGORY_LIST_LIMIT,
        searchByCategoryCode:string,
        postingStatus?:any,
        level?:any, 
        direction? : any,
        column? : any
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.CATEGORY_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.CATEGORY_LIST}?page=${page_id}&limit=${limit}&search=${searchByCategoryCode}&postingStatus=${postingStatus}&categoryLevel=${level}&order=${direction}&column=${column}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { currentPage, totalPages, currentTotalCount, totalCount} = response.data.data;
            const CategoryListData: CategoryListWithPaginationType  = {
                categoryListLogs: response.data.data.categories,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
                lastUpdatedAt : response.data.data?.lastUpdatedAt
            };
            return CategoryListData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async categoryListDownload(
        page_id: number,
        limit = LIST_LIMIT.CATEGORY_LIST_LIMIT,
        searchByCategoryCode:string,
        postingStatus?:any,
        level?:any, 
        direction? : any,
        column? : any
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.CATEGORY_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.CATEGORY_LIST}?page=${page_id}&limit=${limit}&search=${searchByCategoryCode}&postingStatus=${postingStatus}&categoryLevel=${level}&order=${direction}&column=${column}&download=true`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { currentPage, totalPages, currentTotalCount, totalCount} = response.data.data;
            const CategoryListData: CategoryListWithPaginationType  = {
                categoryListLogs: response.data.data.categories,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return CategoryListData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async updateCategory(){
        const response = this.isHitDummyURL
            ? await new AxiosUtils(`${mockAPIPath.UPDATE_CATEGORY}`, this.payload).put()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.UPDATE_CATEGORY}`, this.payload, this.headers).put();
        if (response?.data?.success) {
            return response?.data;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async failedCategoryList(
        page_id:number,
        limit =  LIST_LIMIT.CATEGORY_LIST_LIMIT,
        searchKeyword="",
    ){
        const response= this.isHitDummyURL ? await new AxiosUtils(
            `${mockAPIPath.FAILED_CATEGORY_REQUEST}`,this.payload)
            .get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.FAILED_CATEGORY_REQUEST}?page=${page_id}&limit=${limit}&search=${searchKeyword}`, this.payload, this.headers).get();
        if (response?.data?.success) {
            const { currentPage, totalPages, currentTotalCount, totalCount} = response.data.data;
            const failedCategoryListData: FailedListWithPagination  = {
                failedCategoryListLogs: response.data.data.data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return failedCategoryListData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async successCategoryList(
        page_id:number,
        limit =  LIST_LIMIT.CATEGORY_LIST_LIMIT,
        searchKeyword="",
    ){
        const response= this.isHitDummyURL ? await new AxiosUtils(
            `${mockAPIPath.SUCCESS_TRANSACTION_LIST}`,this.payload)
            .get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SUCCESS_TRANSACTION_LIST}?page=${page_id}&limit=${limit}&search=${searchKeyword?.trim()}`, this.payload, this.headers).get();
        if (response?.data?.success) {
            const { currentPage, totalPages, currentTotalCount, totalCount} = response.data.data;
            const successCategoryListData: CategorySuccessTransactionWithPagination  = {
                successCategoryListLogs: response.data.data.data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return successCategoryListData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async findTransaction (page_id:number,
        limit =  LIST_LIMIT.CATEGORY_LIST_LIMIT,
        searchKeyword:string){
        const response= this.isHitDummyURL ? await new AxiosUtils(
            `${mockAPIPath.FIND_TRANSACTION}`,this.payload)
            .get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.FIND_TRANSACTION}?page=${page_id}&limit=${limit}&search=${searchKeyword}`, this.payload, this.headers).get();
        if (response?.data?.success) {
            const { currentPage, totalPages, currentTotalCount, totalCount} = response.data.data;
            const searchedTransactionsList: FindTransactionWithPagination  = {
                searchedCategoryListLogs: response.data.data.data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return searchedTransactionsList;
        }else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async productCategoryMapping( cainzProductCode : string ){
        const response = this.isHitDummyURL
            ? await new AxiosUtils(`${mockAPIPath.CATEGORY_INTEGRATION}?cainzProductCode=${cainzProductCode}`, this.payload).put()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.CATEGORY_INTEGRATION}?cainzProductCode=${cainzProductCode}`, this.payload, this.headers).put();

        let isMappingSave;
        if(response?.data?.success==true) {
            isMappingSave = response?.data;
            return isMappingSave;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
        
    }
}

export default CategoryListService;